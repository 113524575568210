import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function Navbar() {
  function toggleMenu() {
    let menu = document.getElementById("mobileMenu").classList;
    let burgerIcon = document.getElementById("burgerIcon").classList;

    menu.toggle("showMenu");
    burgerIcon.toggle("burgerAnimation");
  }

  return (
    <div className="navbar">
      <div className="logo">
        <Link to="/">
          <StaticImage
            src="../images/logo.png"
            placeholder="blurred"
            layout="fixed"
            width={250}
          />
        </Link>
      </div>

      <div className="burgericon" id="burgerIcon" onClick={toggleMenu}>
        <p className="p1"></p>
        <p className="p2"></p>
        <p className="p3"></p>
      </div>

      <div className="mobileMenu" id="mobileMenu">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/diensten">Diensten</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>

      <div className="navLinks">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/diensten">Diensten</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
